/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import Seo from '../../seo/productionbook';
import Sections from 'views/productionbook/Sections';
import Main from 'layouts/Productionbook';
import WithLayout from 'WithLayout';

const SectionsPage = () => {
  return (
    <WithLayout
      seo={Seo}
      component={Sections}
      layout={Main}
    />
  );
};

export default SectionsPage;
