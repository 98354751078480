export const articles2 = [
  {
    cover: {
      src: '../../../../../assets/images/qeerio/productionbook/sections/contactsection.webp',
      srcSet: '../../../../../assets/images/qeerio/productionbook/sections/contactsection.webp'
    },
    title: 'Contacts',
    subtitle:
      "A section to store your event's contact list with shortcuts to quickly call, message or whatsapp",
    tags: ['Call', 'Text', 'Whatsapp', 'Find the person in charge for something', 'Quick share contact'],
  },
  {
    cover: {
      src: '../../../../../assets/images/qeerio/productionbook/sections/locationsection.webp',
      srcSet: '../../../../../assets/images/qeerio/productionbook/sections/locationsection.webp'
    },
    title: "Locations",
    subtitle:
      "A section to store your event's location with shortcuts to quickly get direction with your favorite GPS app",
    tags: ['Get adresses', 'Get directions', 'Quick share address', 'Waze', 'Google maps'],
  },
  {
    cover: {
      src: '../../../../../assets/images/qeerio/productionbook/sections/timelinesection.webp',
      srcSet: '../../../../../assets/images/qeerio/productionbook/sections/timelinesection.webp'
    },
    title: "Timeline",
    subtitle:
      'A timeline regrouping all keys moments of your event.',
    tags: ['Get event planning', 'Set reminder notifications']
  },
  {
    cover: {
      src: '../../../../../assets/images/qeerio/productionbook/sections/todo.webp',
      srcSet: '../../../../../assets/images/qeerio/productionbook/sections/todo.webp'
    },
    title: "Todo list",
    subtitle:
      'A section to last all the important tasks that need to be done on your event!',
    tags: ['List tasks', 'Notify task assignee', 'Prioritize tasks']
  },
  {
    cover: {
      src: '../../../../../assets/images/qeerio/productionbook/sections/chatsection.webp',
      srcSet: '../../../../../assets/images/qeerio/productionbook/sections/chatsection.webp'
    },
    title: "Chat",
    subtitle:
      'Discuss and share important new information about the event. No switching to a messaging app and add all event actors to a group chat',
    tags: ['Share new informations', 'Share pictures', 'Avoid using external chat app']
  },
  {
    cover: {
      src: '../../../../../assets/images/qeerio/productionbook/sections/listsection.webp',
      srcSet: '../../../../../assets/images/qeerio/productionbook/sections/listsection.webp'
    },
    title: "Simple list",
    subtitle:
      'A flexible section allowing to store anykind of information as a list of items.',
    tags: ['List of materials', 'Pre-event todo list', 'Shuttle list', 'Hotel list', 'And many more...']
  },
  {
    cover: {
      src: '../../../../../assets/images/qeerio/productionbook/sections/singlepdfsection.webp',
      srcSet: '../../../../../assets/images/qeerio/productionbook/sections/singlepdfsection.webp',
    },
    title: "Simple pdf",
    subtitle:
      'A section giving quick access to a pdf containing key information about your event.',
    tags: ['Seating plan', 'Main menu', 'Any information on pdf']
  },
  {
    cover: {
      src: '../../../../../assets/images/qeerio/productionbook/sections/multipdfsection.webp',
      srcSet: '../../../../../assets/images/qeerio/productionbook/sections/multipdfsection.webp',
    },
    title: "List of pdf",
    subtitle:
      'A flexible section allowing you to store multiple pdfs structured as a list.',
    tags: ['Blueprints event build up', 'Table plans', 'Travel documents', 'Call sheets', 'Any information on pdf']
  },
  {
    cover: {
      src: '../../../../../assets/images/qeerio/productionbook/sections/mapsection.webp',
      srcSet: '../../../../../assets/images/qeerio/productionbook/sections/mapsection.webp',
    },
    title: "Map",
    subtitle:
      'A section with a map allowing you to see where all event users are located',
    tags: ['Find anyone on event']
  }
];
